import { useState, useEffect } from "react";
import { Book, Input, Annexe } from "./components.js";
import styled from "styled-components";

import Suspect from "./Suspect";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
`;

const BookWrapper = styled.div`
  width: 80%;
  height: 100%;
  position: relative;
  /* display: flex;
  justify-content: center;
  align-items: center; */
`;

const InputWrapper = styled.div`
  width: 20%;
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 50px;
  & input {
    margin: 30px 0;
    width: 70%;
  }
`;

const AudioContainer = styled.div`
  position: fixed;
  left: -600px;
`;

const Button = styled.div`
  margin: 20px 0;
  width: 70%;
  background-color: #f9ae14;
  border: none;
  padding: 10px 20px;
  text-align: center;
  font-size: 40px;
  outline: none;
  cursor: pointer;
  position: relative;
  transition: color 0.2s ease, background-color 0.2s ease;
  &.suspectOpen {
    color: #f9ae14;
    background-color: #000;
  }
  &::after {
    content: "";
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    position: absolute;
    left: -4px;
    top: -4px;
    background-image: url(/images/button/top-left.png);
    background-position: top left;
    background-size: 300px 150px;
  }
  &::before {
    content: "";
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    position: absolute;
    right: -4px;
    bottom: -4px;
    background-image: url(/images/button/bottom-right.png);
    background-position: bottom right;
    background-size: 300px 150px;
  }
`;

const Consigne = (props) => {
  const { onStart } = props;
  const [pageConsigne, setPageConsigne] = useState(null);
  const [suspectOpen, setSuspectOpen] = useState(false);
  const [soundPlayed, setSoundPlayed] = useState(false);

  const setSound = (sound, id) => {
    let audioContainer = document.getElementById("audio-consigne");
    if (!document.getElementById(id)) {
      let audio = document.createElement("audio");
      audio.src = sound;
      audioContainer.appendChild(audio);
      audio.id = id;
      audio.play();
      audio.onended = () => {
        audioContainer.removeChild(audio);
      };
    }
  };

  const removeSound = (id) => {
    let audioContainer = document.getElementById("audio-consigne");
    let audio = document.getElementById(id);
    if (audio) audioContainer.removeChild(audio);
  };

  useEffect(() => {
    if (soundPlayed === false)
      setSound("/sounds/consigne1.mp3", "audio-consigne1");
  }, []);

  const onNextClick = () => {
    if (pageConsigne === 0 || pageConsigne === null) {
      setPageConsigne(1);
      removeSound("audio-consigne1");
      if (soundPlayed === false)
        setSound("/sounds/consigne2.mp3", "audio-consigne2");
      setSoundPlayed(true);
    } else {
      setPageConsigne(0);
      removeSound("audio-consigne2");
    }
  };
  console.log(props);

  return (
    <Container>
      <AudioContainer id="audio-consigne"></AudioContainer>
      <BookWrapper>
        <Book
          src={
            pageConsigne === 0
              ? "/images/carnet/page2.png"
              : pageConsigne === 1
              ? "/images/carnet/page3.png"
              : "/images/carnet/page2.png"
          }
        ></Book>{" "}
      </BookWrapper>
      <InputWrapper>
        <Annexe
          href="/annexe/DOSSIER-ENQUETE-MAGIC-SHOW-CARNET.pdf"
          target="_blank"
        >
          Annexe
        </Annexe>
        <Button
          className={(suspectOpen && "suspectOpen") || ""}
          onClick={() => setSuspectOpen(!suspectOpen)}
        >
          Suspects
        </Button>
        <Button onClick={onNextClick}>
          {((pageConsigne === 0 || pageConsigne === null) && "Suivant") ||
            (pageConsigne === 1 && "Précédent")}
        </Button>
        {pageConsigne !== null && (
          <Button
            onClick={() => {
              onStart();
              removeSound("audio-consigne2");
            }}
          >
            Commencer
          </Button>
        )}
      </InputWrapper>
      {/* {suspectOpen && ( */}
      <Suspect
        consigne
        className={suspectOpen && "show-answer"}
        // onValidate={(id) => onValidate(id)}
        onCancel={() => setSuspectOpen(false)}
        // wrongAnswers={wrongAnswers}
      ></Suspect>
      {/* )} */}
    </Container>
  );
};

export default Consigne;
