import { configureStore } from "@reduxjs/toolkit";
import { createStore, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import pointsReducer from "../features/points/pointsSlice";
import appStateReducer from "../features/appState/appStateSlice";
import enigmeReducer from "../features/enigm/enigmSlice";

const persistConfig = {
  key: "root",
  storage,
};

const combinedReducers = combineReducers({
  appState: appStateReducer,
  points: pointsReducer,
  enigm: enigmeReducer,
});

const persistedReducer = persistReducer(persistConfig, combinedReducers);

// export default () => {
//   let store = createStore(persistedAppStateReducer);
//   let persistor = persistStore(store);
//   return { store, persistor };
// };

export const store = configureStore({ reducer: persistedReducer });

export const persistor = persistStore(store);

// export default configureStore({
//   reducer: {
//     points: pointsReducer,
//     appState: appStateReducer,
//   },
// });
