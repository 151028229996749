import { useState, useEffect } from "react";
import { Book, Input, Annexe } from "./components.js";
import styled from "styled-components";
import Suspect from "./Suspect";
import {
  setUseIndice,
  setGoodAnswer,
  setWrongAnswer,
} from "../features/points/pointsSlice";
import {
  setGameWon,
  selectStartedAt,
} from "../features/appState/appStateSlice";
import { useSelector, useDispatch } from "react-redux";
import {
  selectIndiceUsed,
  setIndiceUsed,
  setEnigmStartedAt,
  selectShowIndice,
  setShowIndice,
  setAnswer,
  selectAnswers,
  selectEngimStartedAt,
} from "../features/enigm/enigmSlice.js";
import { current } from "@reduxjs/toolkit";

const indiceTimeToShow = 240000;
// const indiceTimeToShow = 2400;

const res = [1, 4, 9, 2, 8, 6, 7, 3, 5];

const indices = [
  {
    indice: `INDICE: Regardez la première lettre de la valeur de chaque carte.
Ex : S(ix)A(s)T(rois)A(s)N(euf)`,
    id: 1,
  },
  {
    indice: `INDICE : Le point est égale à un. La Croix à deux... Ainsi de suite. Il semblerait que le chapeau porte une étiquette « Magicien 3 ». Mais qui cela peut il bien être ?`,
    id: 2,
  },
  {
    indice: `INDICE: Servez-vous de l’alphabet des signes dans l’annexe pour décrypter le message caché.`,
    id: 3,
  },
  {
    indice: `INDICE: Avez-vous remarqué les symboles sur les baguettes dans l’annexe ? Seulement 2 correspondent aux plis de l’affiche.
    Une fois identifiées, pliez l’affiche en suivant les axes des symboles donnés par les deux baguettes pour masquer les lettres inutiles. Vous devriez ensuite pouvoir remettre de l’ordre avec les lettres qu’il vous reste.`,
    id: 4,
  },
  {
    indice: `INDICE: Avez-vous complété le texte ? Il semblerait qu’un calcul soit caché dedans. Faites attention aux mots en majuscules. Une fois le résultat obtenu, reportez vous à la liste des suspects.`,
    id: 5,
  },
  {
    indice: `INDICE: Intéressez vous aux particularités des suspects.`,
    id: 6,
  },
  {
    indice: `INDICE: 
    La colombe et blanche, le chapeau noir, et la bouches est rouge. En partant de l’étoile jaune, suivez les foulards pour arriver jusqu’à la bleue. Il semblerait que votre tracé représente des initiales.`,
    id: 7,
  },
  {
    indice: `INDICE: Il semblerait que certaines lettres ont été griffonnées sur l’affiche de Thurston dans l’annexe. Utilisez les sons entendus par la mamie pour identifier les éléments afin de trouver les lettres constituant le code caché dans l’affiche. La liste des suspects vous sera utile. Ex : Pan = Revolver.`,
    id: 8,
  },
  {
    indice: `INDICE: À la fin il n’en reste plus qu’un.`,
    id: 9,
  },
];

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
`;

const BookWrapper = styled.div`
  width: 80%;
  height: 100%;
  position: relative;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
`;

const InputWrapper = styled.div`
  width: 20%;
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 50px;
  & input {
    margin: 30px 0;
    width: 70%;
  }
  & .indice-wrapper {
    max-height: 50%;
    overflow: scroll;
    max-width: 80%;

    & > p {
      /* position: absolute; */

      bottom: 0;
      white-space: break-spaces;
      word-break: break-word;
      font-family: "Bradley";
      color: #fff;
      font-size: 20px;
    }
  }
`;

const Button = styled.div`
  margin: 20px 0;
  width: 70%;
  background-color: #f9ae14;
  border: none;
  padding: 10px 20px;
  text-align: center;
  font-size: 40px;
  outline: none;
  cursor: pointer;
  position: relative;
  transition: color 0.2s ease, background-color 0.2s ease;
  &.suspectOpen {
    color: #f9ae14;
    background-color: #000;
  }
  &::after {
    content: "";
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    position: absolute;
    left: -4px;
    top: -4px;
    background-image: url(/images/button/top-left.png);
    background-position: top left;
    background-size: 300px 150px;
  }
  &::before {
    content: "";
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    position: absolute;
    right: -4px;
    bottom: -4px;
    background-image: url(/images/button/bottom-right.png);
    background-position: bottom right;
    background-size: 300px 150px;
  }
`;

const VideoEnigm6 = styled.video`
  position: absolute;
  height: 50%;
  left: 0;
  right: 0;
  margin: 30px auto;
  outline: none;
  z-index: 999;
`;

const AudioContainer = styled.div`
  position: fixed;
  left: -600px;
`;

const Enigm = (props) => {
  const { onStart, currentEnigm, setCurrentEnigm } = props;
  const showIndice = useSelector(selectShowIndice);
  const startedAt = useSelector(selectStartedAt);
  const indiceUsed = useSelector(selectIndiceUsed);
  const enigmStartedAt = useSelector(selectEngimStartedAt);
  const [indiceOpen, setIndiceOpen] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const [suspectOpen, setSuspectOpen] = useState(false);
  const [wrongAnswers, addWrongAnswers] = useState([]);
  const dispatch = useDispatch();

  let indiceTimeLeft = enigmStartedAt + indiceTimeToShow - Date.now();

  let timeOut;

  const setSound = (src, id) => {
    let audioContainer = document.getElementById("audio-container");
    let audio = document.createElement("audio");
    if (!document.getElementById(id)) {
      audio.src = src;
      audioContainer.appendChild(audio);
      if (id) audio.id = id;
      audio.play();
      audio.onended = () => {
        audioContainer.removeChild(audio);
      };
    }
  };

  const removeSound = (id) => {
    let audioContainer = document.getElementById("audio-container");
    let audio = document.getElementById(id);
    if (audio) audioContainer.removeChild(audio);
  };

  useEffect(() => {
    if (enigmStartedAt === null) {
      dispatch(setEnigmStartedAt(Date.now()));
    }
    if (indiceTimeLeft < 0) {
      dispatch(setShowIndice(true));
    }

    if (indiceUsed) {
      setIndiceOpen(true);
    }
    setSound(
      `/sounds/enigm/enigm${currentEnigm}.mp3`,
      `audio-enigm${currentEnigm}`
    );
  }, []);

  const clueTimeOut = () =>
    (timeOut = setTimeout(() => {
      dispatch(setShowIndice(true));
    }, indiceTimeLeft));

  useEffect(() => {
    if (indiceTimeLeft > 0) {
      clearTimeout(timeOut);
      clueTimeOut();
    }
    if (currentEnigm === 1) {
      setSound(
        `/sounds/enigm/enigm${currentEnigm}.mp3`,
        `audio-enigm${currentEnigm}`
      );
    } else {
      setTimeout(() => {
        setSound(
          `/sounds/enigm/enigm${currentEnigm}.mp3`,
          `audio-enigm${currentEnigm}`
        );
      }, 3000);
    }
  }, [currentEnigm]);

  const onIndiceClick = () => {
    setIndiceOpen(true);
    if (indiceUsed === false) {
      dispatch(setIndiceUsed(true));
      dispatch(setUseIndice());
    }
  };

  const onValidate = (id) => {
    if (id === res[currentEnigm - 1]) {
      setShowAnswer(false);
      dispatch(setEnigmStartedAt(Date.now()));
      dispatch(setShowIndice(false));
      dispatch(setIndiceUsed(false));
      setSuspectOpen(false);
      dispatch(setGoodAnswer());
      setIndiceOpen(false);
      addWrongAnswers([]);
      dispatch(setAnswer(id));
      removeSound(`audio-enigm${currentEnigm}`);
      dispatch(setCurrentEnigm(currentEnigm + 1));
      setSound("/sounds/good-answer.mp3");
      if (currentEnigm === 9) {
        dispatch(setGameWon(true));
      }
    } else {
      console.log("WRONG !!!!");
      if (wrongAnswers.findIndex((item) => item === id) === -1) {
        setSound("/sounds/bad-answer.mp3");
        dispatch(setWrongAnswer());
        addWrongAnswers([...wrongAnswers, id]);
      }
    }
  };
  console.log(showAnswer);

  return (
    <Container>
      {currentEnigm === 6 && (
        <VideoEnigm6 controls src="/video/video-enigm6.mp4"></VideoEnigm6>
      )}
      <AudioContainer id="audio-container"></AudioContainer>
      <BookWrapper>
        <Book
          className={
            (suspectOpen && "suspect") ||
            (currentEnigm === 6 && "book-enigm6") ||
            (currentEnigm === 8 && "book-enigm8") ||
            ""
          }
          src={
            suspectOpen
              ? `/images/carnet/suspect${currentEnigm}.png`
              : `/images/carnet/enigm${currentEnigm}.png`
          }
        ></Book>{" "}
      </BookWrapper>
      <InputWrapper>
        {indiceOpen && (
          <div className="indice-wrapper">
            <p>{indices.find((item) => item.id === currentEnigm).indice}</p>
          </div>
        )}
        <Annexe
          href="/annexe/DOSSIER-ENQUETE-MAGIC-SHOW-CARNET.pdf"
          target="_blank"
        >
          Annexe
        </Annexe>
        {/* <Button
          className={(suspectOpen && "suspectOpen") || ""}
          onClick={() => setSuspectOpen(!suspectOpen)}
        >
          Suspects
        </Button> */}
        {showIndice && !indiceOpen && (
          <Button onClick={onIndiceClick}>Aide</Button>
        )}

        <Button
          onClick={() => {
            console.log("click");
            setShowAnswer(true);
          }}
        >
          Suspects
        </Button>
      </InputWrapper>
      {/* {showAnswer && ( */}
      <Suspect
        onValidate={(id) => onValidate(id)}
        onCancel={() => setShowAnswer(false)}
        className={(showAnswer && "show-answer") || ""}
        // style={showAnswer && { opacity: 1, pointerEvents: "auto" }}
        wrongAnswers={wrongAnswers}
      ></Suspect>
      {/* )} */}
    </Container>
  );
};

export default Enigm;
