import { createSlice } from "@reduxjs/toolkit";

export const enigmSlice = createSlice({
  name: "enigm",
  initialState: {
    currentEnigm: null,
    indiceUsed: false,
    showIndice: false,
    enigmStartedAt: null,
    answers: [],
  },
  reducers: {
    setCurrentEnigm: (state, action) => {
      state.currentEnigm = action.payload;
    },
    setIndiceUsed: (state, action) => {
      state.indiceUsed = action.payload;
    },
    setShowIndice: (state, action) => {
      state.showIndice = action.payload;
    },
    setAnswer: (state, action) => {
      state.answers.push(action.payload);
    },
    setEnigmStartedAt: (state, action) => {
      state.enigmStartedAt = action.payload;
    },
  },
});

export const {
  setCurrentEnigm,
  setIndiceUsed,
  setShowIndice,
  setAnswer,
  setEnigmStartedAt,
} = enigmSlice.actions;

export const selectCurrentEnigm = (state) => state.enigm.currentEnigm;
export const selectIndiceUsed = (state) => state.enigm.indiceUsed;
export const selectShowIndice = (state) => state.enigm.showIndice;
export const selectAnswers = (state) => state.enigm.answers;
export const selectEngimStartedAt = (state) => state.enigm.enigmStartedAt;

export default enigmSlice.reducer;
