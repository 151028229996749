import React, { Component, useState, useEffect } from "react";
import styled from "styled-components";
import Countdown from "react-countdown";
import {
  setStartedAt,
  selectStartedAt,
  selectGameStarted,
  setTime,
  selectTime,
  selectGameWon,
} from "../features/appState/appStateSlice";
import { useSelector, useDispatch } from "react-redux";

const Wrapper = styled.div`
  position: fixed;
  font-size: 40px;
  background-color: #f9ae14;
  border: none;
  padding: 10px 20px;
  text-align: center;
  font-size: 40px;
  outline: none;
  top: 30px;
  min-width: 100px;
  left: 30px;
  z-index: 99999999;
  &::after {
    content: "";
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    position: absolute;
    left: -4px;
    top: -4px;
    background-image: url(/images/button/top-left.png);
    background-position: top left;
    background-size: 300px 150px;
  }
  &::before {
    content: "";
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    position: absolute;
    right: -4px;
    bottom: -4px;
    background-image: url(/images/button/bottom-right.png);
    background-position: bottom right;
    background-size: 300px 150px;
  }
`;

const convertToTime = (t) => {
  let totalSeconds = t / 1000;
  let minutes = Math.floor(totalSeconds / 60);
  let seconds = Math.round(totalSeconds % 60);
  if (seconds >= 30) {
    minutes += 1;
  }
  return { minutes: minutes, seconds: seconds };
};

const Completionist = () => <span>Le temps est fini!</span>;

const Timer = (props) => {
  const startedAt = useSelector(selectStartedAt);
  const time = useSelector(selectTime);
  const gameWon = useSelector(selectGameWon);
  const gameStarted = useSelector(selectGameStarted);
  const dispatch = useDispatch();
  const [isPaused, setIsPaused] = useState(false);

  const setStartTime = () => {
    // console.log("timer reset");
    if (startedAt === null) setStartedAt(Date.now());
  };

  const renderer = (props, isPaused, setIsPaused) => {
    const { hours, minutes, seconds, completed, api } = props;
    if (gameStarted === true && completed !== true) {
      api.start();
    }
    if (gameWon === true) {
      setPause();
    }
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <span>{`${hours * 60 + minutes}:${
          seconds < 10 ? `0${seconds}` : seconds
        }`}</span>
      );
    }
  };

  const handleTimerComplete = () => {
    console.log("Timer Complete");
    // let localData = {
    //   ...getLocalData(),
    //   timerFinish: true,
    //   time: { minutes: 70, seconds: 0 },
    // };
    // saveLocalData(localData);
    // if (this.props.gameWon !== true) {
    //   apiCall().then((res) =>
    //     saveLocalData({ ...localData, endEmailSent: true })
    //   );
    // }
  };

  const setPause = () => {
    setIsPaused(true);
  };

  //

  return (
    <Wrapper>
      {isPaused ? (
        `${59 - time.minutes}:${
          60 - time.seconds < 10 ? `0${60 - time.seconds}` : 60 - time.seconds
        }`
      ) : (
        <Countdown
          date={startedAt === null ? Date.now() + 3600000 : startedAt + 3600000}
          onStart={setStartTime}
          autoStart={false}
          onTick={(t) => {
            //   console.log(t);
            let totalSeconds = (Date.now() - startedAt) / 1000;
            let minutes = Math.floor(totalSeconds / 60);
            let seconds = Math.round(totalSeconds % 60);
            dispatch(setTime({ minutes: minutes, seconds: seconds }));
            // console.log(minutes);
            // let localData = {
            //   ...getLocalData(),
            //   time: { minutes: minutes, seconds: seconds },
            // };
            //   console.log(localData);
            // saveLocalData(localData);
          }}
          onComplete={handleTimerComplete}
          // autoStart={false}
          renderer={(props) => renderer(props, isPaused, setPause)}
        />
      )}
    </Wrapper>
  );
};

export default Timer;
