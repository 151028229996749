import logo from "./logo.svg";
import {
  Wrapper,
  HomeVideo,
  PlayButton,
  ClearLS,
  VideoTimeline,
  HomeInputWrapper,
  GameWonWrapper,
  InputBg,
  Input,
  Book,
} from "./components/components.js";
import Timer from "./components/Timer";
import { useSelector, useDispatch } from "react-redux";
import Consigne from "./components/Consigne";
import "./App.css";
import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { persistor } from "./app/store";
import { db } from "../src/firebase";

import Enigm from "./components/Enigm.js";

import {
  setVideoStart,
  setGameStarted,
  setShowButton,
  setShowConsigne,
  setCodeVerification,
  setCode,
  setPlayer,
  selectPlayer,
  setStartedAt,
  selectCodeVerification,
  selectCode,
  selectVideoStarted,
  selectShowButton,
  selectShowConsigne,
  selectGameStarted,
  selectStartedAt,
  selectGameWon,
  asyncSetCodeVerification,
} from "./features/appState/appStateSlice";

import {
  selectCurrentEnigm,
  setCurrentEnigm,
  setEnigmStartedAt,
} from "./features/enigm/enigmSlice";

import { selectPoints } from "./features/points/pointsSlice";

const Button = styled.div`
  width: 250px;
  background-color: #f9ae14;
  border: none;
  padding: 20px 20px;
  text-align: center;
  position: relative;
  font-size: 40px;
  outline: none;
  cursor: pointer;
  &::after {
    content: "";
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    position: absolute;
    pointer-events: none;
    left: -4px;
    top: -4px;
    background-image: url(/images/button/top-left.png);
    background-position: top left;
    background-size: 300px 150px;
  }
  &::before {
    content: "";
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    position: absolute;
    pointer-events: none;
    right: -4px;
    bottom: -4px;
    background-image: url(/images/button/bottom-right.png);
    background-position: bottom right;
    background-size: 300px 150px;
  }
`;

const Points = styled.div`
  background-color: #f9ae14;
  position: fixed;
  border: none;
  right: 30px;
  top: 30px;
  padding: 10px 20px;
  text-align: center;
  font-size: 40px;
  outline: none;
  cursor: pointer;
  z-index: 99999;
  &::after {
    content: "";
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    position: absolute;
    left: -4px;
    top: -4px;
    background-image: url(/images/button/top-left.png);
    background-position: top left;
    background-size: 300px 150px;
  }
  &::before {
    content: "";
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    position: absolute;
    right: -4px;
    bottom: -4px;
    background-image: url(/images/button/bottom-right.png);
    background-position: bottom right;
    background-size: 300px 150px;
  }
`;

const AudioLoop = styled.audio`
  position: fixed;
  z-index: 999999;
  left: -600px;
`;

const AudioContainer = styled.div`
  position: fixed;
  left: -600px;
`;

const App = () => {
  const dispatch = useDispatch();

  const videoStart = useSelector(selectVideoStarted);
  const showButton = useSelector(selectShowButton);
  const gameStarted = useSelector(selectGameStarted);
  const showConsigne = useSelector(selectShowConsigne);
  const code = useSelector(selectCode);
  const gameWon = useSelector(selectGameWon);
  const codeVerification = useSelector(selectCodeVerification);
  const startedAt = useSelector(selectStartedAt);
  const points = useSelector(selectPoints);
  const player = useSelector(selectPlayer);
  const currentEnigm = useSelector(selectCurrentEnigm);

  const [explication, setExplication] = useState(null);

  // const [player, setName] = useState("");

  const videoRef = useRef(null);
  const audioRef = useRef(null);

  console.log("currentEnigm", currentEnigm);

  useEffect(() => {
    if (
      videoStart === true &&
      showConsigne === false &&
      gameStarted === false
    ) {
      dispatch(setVideoStart(true));
      dispatch(setShowButton(true));
      videoRef.current.currentTime = 35;
    }
    if (showConsigne === true) {
      dispatch(setShowButton(false));
      dispatch(setShowConsigne(true));
    }
  }, []);

  useEffect(() => {
    if (audioRef !== null) {
      if (gameStarted) {
        audioRef.current.play();
      }
    }
  }, [audioRef]);

  useEffect(() => {
    if (gameWon) {
      let container = document.getElementById("game-won-sound");
      let audio = document.createElement("audio");
      audio.src = "/sounds/win-game.mp3";
      container.appendChild(audio);
      audio.play();
    }
  }, [gameWon]);

  const onVideoTimeUdpate = (event) => {
    if (
      videoRef.current &&
      videoRef.current.currentTime > 11 &&
      videoRef.current.currentTime < 12 &&
      showButton === false
    ) {
      dispatch(setShowButton(true));
    }
  };

  useEffect(() => {
    console.log("codeVerification:", codeVerification);
    if (codeVerification === true && videoRef.current !== null) {
      console.log("removing eventlistener");
      videoRef.current.removeEventListener("timeupdate", onVideoTimeUdpate);
    }
  }, [codeVerification]);

  const onPlayButton = () => {
    dispatch(setVideoStart());
    videoRef.current.play();
    // localStorage.setItem("videoStarted", true);
    videoRef.current.addEventListener("timeupdate", onVideoTimeUdpate);
  };

  const onVerifyCode = async () => {
    if (codeVerification === false) {
      dispatch(setCodeVerification(true));
      let codeRef = db.collection("codes").doc(code);
      db.runTransaction(function (transaction) {
        // This code may get re-run multiple times if there are conflicts.
        return transaction.get(codeRef).then(function (codeDoc) {
          if (!codeDoc.exists) {
            throw "Document does not exist!";
          }
          if (codeDoc.data().used !== true) {
            transaction.update(codeRef, {
              used: true,
              player: player,
              usedAt: Date.now(),
              points: 100,
            });
            dispatch(setShowButton(false));
            dispatch(setShowConsigne(true));
            audioRef.current.play();
          } else {
            alert("Le code que vous avez entrer n'est pas valide...");
            dispatch(setCodeVerification(false));
          }
        });
      }).catch(function (error) {
        dispatch(setCodeVerification(false));
        alert("Le code que vous avez entrer n'est pas valide...");
      });
      // dispatch(setCodeVerification(true));
      // let verifyCode = async (code) => {
      //   return new Promise((resolve) => {
      //     setTimeout(() => resolve(true), 2000);
      //   });
      // };

      // console.log(codeVerified);
    }
  };

  const onStartGame = () => {
    if (startedAt === null) {
      console.log("startedAt");
      dispatch(setStartedAt());
      dispatch(setShowConsigne(false));
      dispatch(setGameStarted());
      dispatch(setCurrentEnigm(1));
      dispatch(setEnigmStartedAt(Date.now()));
    }
  };

  return (
    <Wrapper
      className={
        (currentEnigm === 8 && "enigm8") ||
        (currentEnigm === 6 && "enigm6") ||
        (gameWon && "game-won") ||
        ""
      }
    >
      <AudioLoop
        ref={audioRef}
        controls
        loop
        src="/sounds/loop-music.mp3"
      ></AudioLoop>
      {showConsigne === false && gameStarted === false && (
        <>
          <HomeVideo src="/video/home-video.mp4" ref={videoRef}></HomeVideo>
          {videoStart === false && (
            <>
              <PlayButton onClick={onPlayButton}></PlayButton>
            </>
          )}
        </>
      )}
      {
        // CLEAR LOCAL STORAGE
      }
      {/* <ClearLS
        onClick={() => {
          persistor.purge();
          window.location.reload();
        }}
      ></ClearLS> */}

      {showButton && (
        <HomeInputWrapper>
          <InputBg>
            <Input
              placeholder="Nom"
              value={player}
              onChange={(e) => dispatch(setPlayer(e.target.value))}
            />
          </InputBg>
          <InputBg>
            <Input
              placeholder="Code"
              value={code}
              onChange={(e) => dispatch(setCode(e.target.value))}
            />
          </InputBg>
          <Button
            onClick={onVerifyCode}
            style={
              ((code === null ||
                player === null ||
                code === "" ||
                player === "") && {
                backgroundColor: "#646464",
                pointerEvents: "none",
              }) ||
              {}
            }
          >
            {(codeVerification === false && "Abracadabra !") ||
              (codeVerification === true && "Vérification...")}
          </Button>
        </HomeInputWrapper>
      )}
      {showConsigne && (
        <>
          <Consigne onStart={onStartGame}></Consigne>
        </>
      )}
      {gameStarted && gameWon !== true && (
        <Enigm
          setCurrentEnigm={(id) => setCurrentEnigm(id)}
          currentEnigm={currentEnigm}
        ></Enigm>
      )}
      {(showConsigne || gameStarted) && !gameWon && (
        <>
          <Timer>{startedAt - Date.now()}</Timer>
          <Points>{points}/280 points</Points>
        </>
      )}
      {gameWon && (
        <GameWonWrapper>
          <AudioContainer id="game-won-sound"> </AudioContainer>

          <div className="wrapper">
            <h1>Félicitations !</h1>
            <p>
              Vous avez identifié le voleur de trophée. <br /> Je savais que
              vous seriez à la hauteur ! <br />
              Je dois filer, à très vite j’en suis certaine.
            </p>
            <p className="right">Miss Peek</p>
          </div>
          <div className="final-points">{points} points</div>
          <div className="explication-wrapper">
            <div onClick={() => setExplication(1)} className="explication">
              Explications Tours 1 à 4
            </div>
            <div onClick={() => setExplication(2)} className="explication">
              Explications Tours 5 à 8
            </div>
          </div>
          {explication && (
            <div className="modal-explication">
              <div className="close" onClick={() => setExplication(null)}></div>
              <img
                src={`/images/carnet/explication${explication}.png`}
                alt=""
              />
            </div>
          )}
        </GameWonWrapper>
      )}
    </Wrapper>
  );
};

export default App;
