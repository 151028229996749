import { createSlice } from "@reduxjs/toolkit";

export const pointsSlice = createSlice({
  name: "points",
  initialState: {
    value: 100,
  },
  reducers: {
    setWrongAnswer: (state) => {
      state.value -= 8;
    },
    setUseIndice: (state) => {
      state.value -= 5;
    },
    setGoodAnswer: (state) => {
      state.value += 20;
    },
  },
});

export const {
  setWrongAnswer,
  setUseIndice,
  setGoodAnswer,
} = pointsSlice.actions;

export const selectPoints = (state) => state.points.value;

export default pointsSlice.reducer;
