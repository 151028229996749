import { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import { selectAnswers } from "../features/enigm/enigmSlice.js";

const suspects = [
  {
    name: "Sasha Man",
    id: 1,
    info: "Né le 30/03/95 / Peut vous convaincre qu'il a de beaux cheveux.",
  },
  {
    name: "Marylou Dini",
    id: 2,
    info: "Née le 03/08/79 / Le spiritisme n'a pas de secret pour elle.",
  },
  {
    name: "Emma Rabbou",
    id: 3,
    info: "Née le 04/04/98 / Des bouts de ficelle lui suffisent.",
  },
  {
    name: "Cécile Lusion",
    id: 4,
    info: "Née le 13/08/88 / Ses tours retournent le cerveau.",
  },
  {
    name: "Simba Gueight",
    id: 5,
    info: "Né le 14/01/84 / Met 6 secondees pour sortir le lapin du chapeau.",
  },
  {
    name: "Baha Bile",
    id: 6,
    info: "Né le 16/04/94 / Son chiffre porte bonheur est le 13.",
  },
  {
    name: "Candice Parission",
    id: 7,
    info: "Née le 22/12/87 / On la voit, on ne la voit plus.",
  },
  { name: "Amir Akle", id: 8, info: "Né le 14/03/87 / Le faiseur de prodige." },
  {
    name: "Josefa Kir",
    id: 9,
    info: "Née le 06/09/74 / Peut marcher sur des lames de rasoir.",
  },
];

const AnswerContainer = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.9);
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  &.show-answer {
    opacity: 1;
    pointer-events: auto;
  }
  & > h2 {
    color: #fff;
    font-size: 50px;
    text-align: center;
    font-weight: 300;
  }
`;

const AnswerButtonWrapper = styled.div`
  display: flex;
  & > .no-selected {
    background-color: #6d6d6d;
    pointer-events: none;
  }
`;

const AnswerButton = styled.div`
  margin: 20px 0;
  width: 70%;
  margin: 0 20px;
  background-color: #f9ae14;
  border: none;
  padding: 10px 20px;
  text-align: center;
  font-size: 40px;
  outline: none;
  cursor: pointer;
  position: relative;
  &.no-selected {
    background-color: #6d6d6d;
    pointer-events: none;
  }

  &::after {
    content: "";
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    position: absolute;
    left: -4px;
    top: -4px;
    background-image: url(/images/button/top-left.png);
    background-position: top left;
    background-size: 300px 150px;
  }
  &::before {
    content: "";
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    position: absolute;
    right: -4px;
    bottom: -4px;
    background-image: url(/images/button/bottom-right.png);
    background-position: bottom right;
    background-size: 300px 150px;
  }
`;

const AnswerWrapper = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 1200px;
  max-width: 100%;
  & > div:hover {
    & > h2 {
      /* color: #f9ae14; */
      text-shadow: #fff 0px 0 15px;
    }
  }
  & > div.selected {
    & > h2  {
      color: #f9ae14;
      text-shadow: #f9ae14 0px 0 15px;
    }
  }
  & > div.wrong-answer {
    pointer-events: none;
    & > h2 {
      text-shadow: #f91414 0px 0 15px;
      color: #f91414;
    }
  }
  & > div.answered {
    pointer-events: none;
    opacity: 0.2;
  }
  & > div {
    display: flex;
    cursor: pointer;
    /* height: 250px; */
    width: 200px;
    margin: 10px 10px;
    flex-direction: column;
    align-items: center;
    position: relative;
    & > img {
      height: 100px;
    }
    & p {
      color: #fff;
      text-align: center;
      margin: 0;
      font-size: 15px;
      font-family: "UndercurrentBTN";
    }
    & > h2 {
      color: #fff;
      font-weight: 300;
      font-size: 30px;
      margin: 10px 0;
      transition: color.2s ease, text-shadow 0.2s ease;
    }
  }
  @media (max-width: 1100px) {
    overflow-x: scroll;
    flex-flow: row wrap;
  }
`;

const Suspect = ({
  onValidate,
  onCancel,
  wrongAnswers,
  consigne,
  className,
}) => {
  const answers = useSelector(selectAnswers);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  console.log("wrongAnswers", answers);

  const checkIfWrongAnswersContain = (id) => {
    if (consigne) return false;
    return wrongAnswers.findIndex((item) => item === id) !== -1;
  };

  const getSuspectClass = (id) => {
    let classes = "";
    if (selectedAnswer === id && checkIfWrongAnswersContain(id) === false) {
      classes = "selected";
    } else if (checkIfWrongAnswersContain(id) === true) {
      classes = "wrong-answer";
    } else {
      classes = "";
    }
    if (answers.findIndex((item) => item === id) !== -1) {
      classes = "answered";
    }
    return classes;
  };

  return (
    <AnswerContainer className={className}>
      <h2>
        {consigne
          ? "Liste des suspects"
          : "Selectionnez un suspect à disculper"}
      </h2>
      <AnswerWrapper>
        {suspects.map((item, i) => (
          <div
            key={i}
            onClick={() =>
              setSelectedAnswer(selectedAnswer === item.id ? null : item.id)
            }
            className={getSuspectClass(item.id)}
            style={consigne && { pointerEvents: "none" }}
          >
            <img src={`/images/suspect/${item.id}.png`} alt="" />
            <h2>{item.name}</h2>
            <div>
              {item.info.split(" / ").map((t) => (
                <p>{t}</p>
              ))}
            </div>
          </div>
        ))}
      </AnswerWrapper>
      <AnswerButtonWrapper>
        {!consigne && (
          <AnswerButton
            className={(selectedAnswer === null && "no-selected") || ""}
            onClick={() => onValidate(selectedAnswer)}
          >
            Valider
          </AnswerButton>
        )}
        <AnswerButton onClick={onCancel}>Fermer</AnswerButton>
      </AnswerButtonWrapper>
    </AnswerContainer>
  );
};

export default Suspect;
