import styled, { keyframes } from "styled-components";

const animButton = keyframes`
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-image: url("/images/background/bg.jpg");
  background-size: cover;
  background-position: center;
  &.enigm8 {
    background-image: url("/images/background/bgpage11.jpg");
  }
  &.enigm6 {
    background-image: url("/images/background/bgpage9.jpg");
  }
  &.game-won {
    background-image: url("/images/background/bg-won.jpg");
  }
`;

export const Annexe = styled.a`
  display: block;
  margin: 20px 0;
  width: 70%;
  color: #000;
  text-decoration: none;
  background-color: #f9ae14;
  border: none;
  padding: 10px 20px;
  text-align: center;
  font-size: 40px;
  outline: none;
  cursor: pointer;
  position: relative;
  transition: color 0.2s ease, background-color 0.2s ease;
  &.suspectOpen {
    color: #f9ae14;
    background-color: #000;
  }
  &::after {
    content: "";
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    position: absolute;
    left: -4px;
    top: -4px;
    background-image: url(/images/button/top-left.png);
    background-position: top left;
    background-size: 300px 150px;
  }
  &::before {
    content: "";
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    position: absolute;
    right: -4px;
    bottom: -4px;
    background-image: url(/images/button/bottom-right.png);
    background-position: bottom right;
    background-size: 300px 150px;
  }
`;

export const HomeVideo = styled.video`
  min-width: 100vw;
  min-height: 100vh;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const PlayButton = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: background-color 0.2s ease;
  cursor: pointer;
  &:hover {
    background-color: #fff;
  }
  &::after {
    content: "";
    position: absolute;
    left: 7px;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 17.5px 0 17.5px 35px;
    border-color: transparent transparent transparent #000000;
  }
`;

export const VideoTimeline = styled.div`
  position: absolute;
  width: 200px;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.5);
  bottom: 5px;
  left: 0;
  right: 0;
  margin: 0 auto;
  &::after {
    content: "";
    width: ${({ currentTime, duration }) => (currentTime / duration) * 100}%;
    height: 2px;
    position: absolute;
    background-color: #fff;
    left: 0;
    top: 0;
  }
`;

export const ClearLS = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;

  background-color: #f9ae14;
  border-radius: 100px;
  background-position: center;
  background-size: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("https://www.a3s-france.fr/wp-content/uploads/2017/01/reload.png");
  right: 500px;
  top: 30px;
  cursor: pointer;
  z-index: 999999;
`;

export const InputBg = styled.div`
  background-color: #f9ae14;
  position: relative;
  &::after {
    content: "";
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    pointer-events: none;
    position: absolute;
    left: -4px;
    top: -4px;
    background-image: url(/images/button/top-left.png);
    background-position: top left;
    background-size: 300px 150px;
  }
  &::before {
    content: "";
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    pointer-events: none;
    position: absolute;
    right: -4px;
    bottom: -4px;
    background-image: url(/images/button/bottom-right.png);
    background-position: bottom right;
    background-size: 300px 150px;
  }
`;

export const Input = styled.input`
  background-color: transparent;
  border: none;
  padding: 20px 20px;
  position: relative;
  text-align: center;
  font-size: 40px;
  font-family: "SignPainter";
  width: 230px;
  outline: none;
  &[type="submit"] {
    cursor: pointer;
  }
`;

export const HomeInputWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  position: absolute;
  bottom: 50px;
  width: calc(100% - 100px);
  padding: 0 50px;
  animation: ${animButton} 1s ease;
`;

export const Book = styled.img`
  max-width: 100%;
  max-height: 90%;
  top: 15%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 999;
  transition: top 0.4s ease;
  position: absolute;
  &.book-enigm6 {
    /* margin-top: calc(50% + 60px); */
    top: calc(50% + 60px);
  }
  &.book-enigm8 {
    top: calc(50%);
    /* margin-top: 75%; */
  }
`;

export const Points = styled.div`
  background-color: #f9ae14;
  border: none;
  padding: 10px 20px;
  text-align: center;
  font-size: 40px;
  width: 150px;
  outline: none;
  z-index: 999999;
`;

export const Timer = styled.div`
  background-color: #f9ae14;
  position: absolute;
  left: 30px;
  top: 30px;
  border: none;
  padding: 10px 20px;
  text-align: center;
  font-size: 40px;
  width: 150px;
  outline: none;
`;

export const GameWonWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  & > .wrapper {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    top: -70px;
    bottom: 0;
    margin: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 550px;
    transform: rotate(-10deg);
    & > h1 {
      font-size: 120px;
      font-weight: 300;
      color: #fff;
      margin: 20px;
    }
    & > p {
      color: #fff;
      font-size: 40px;
      margin: 0;
      width: 100%;
      text-align: justify;
      &.right {
        text-align: right;
        margin-top: 10px;
      }
    }
  }
  & .explication-wrapper {
    position: absolute;
    bottom: 5%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 550px;
    display: flex;
    & > .explication {
      border: 3px solid #fff;
      margin: 0 10px;
      width: 250px;
      padding: 10px 0;
      cursor: pointer;
      color: #fff;
      font-size: 30px;
      text-align: center;
      font-weight: 300;
    }
  }

  & .modal-explication {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;

    & .close {
      position: absolute;
      right: 20px;
      top: 20px;
      height: 50px;
      width: 50px;
      cursor: pointer;
      &::after,
      ::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        width: 100%;
        height: 2px;
        background-color: #fff;
        transform-origin: center;
      }
      &::after {
        transform: rotate(45deg);
      }
      &::before {
        transform: rotate(-45deg);
      }
    }

    & img {
      max-width: 80%;
      max-height: 100%;
      position: relative;
      top: 10%;
    }
  }
  & > .final-points {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20%;
    margin: 0 auto;
    text-align: center;
    border: 3px solid #fff;
    padding: 10px 0;
    width: 400px;
    color: #fff;
    font-size: 60px;
    font-weight: 300;
  }
`;
