import { createSlice } from "@reduxjs/toolkit";

export const appStateSlice = createSlice({
  name: "appState",
  initialState: {
    gameStarted: false,
    videoStart: false,
    showButton: false,
    showConsigne: false,
    codeVerification: false,
    code: null,
    startedAt: null,
    gameWon: null,
    player: null,
    time: {
      minutes: 0,
      seconds: 0,
    },
  },
  reducers: {
    setVideoStart: (state) => {
      state.videoStart = true;
    },
    setGameStarted: (state) => {
      state.gameStarted = true;
    },
    setShowButton: (state, action) => {
      state.showButton = action.payload;
    },
    setShowConsigne: (state, action) => {
      state.showConsigne = action.payload;
    },
    setCodeVerification: (state, action) => {
      state.codeVerification = action.payload;
    },
    setCode: (state, action) => {
      state.code = action.payload;
    },
    setPlayer: (state, action) => {
      state.player = action.payload;
    },
    setStartedAt: (state) => {
      state.startedAt = Date.now();
    },
    setTime: (state, payload) => {
      state.time = payload.payload;
    },
    setGameWon: (state, action) => {
      state.gameWon = action.payload;
    },
  },
});

export const {
  setVideoStart,
  setGameStarted,
  setShowButton,
  setShowConsigne,
  setCodeVerification,
  setCode,
  setPlayer,
  setPoints,
  setStartedAt,
  setTime,
  setGameWon,
} = appStateSlice.actions;

export const asyncSetCodeVerification = (bool) => (dispatch) => {
  setTimeout(() => {
    dispatch(setCodeVerification(true));
  }, 2000);
};

export const selectCodeVerification = (state) =>
  state.appState.codeVerification;
export const selectVideoStarted = (state) => state.appState.videoStart;
export const selectShowButton = (state) => state.appState.showButton;
export const selectShowConsigne = (state) => state.appState.showConsigne;
export const selectGameStarted = (state) => state.appState.gameStarted;
export const selectCode = (state) => state.appState.code;
export const selectStartedAt = (state) => state.appState.startedAt;
export const selectTime = (state) => state.appState.time;
export const selectGameWon = (state) => state.appState.gameWon;
export const selectPlayer = (state) => state.appState.player;

export default appStateSlice.reducer;
